#content {
  overflow-wrap: break-word;
  word-break: break-word;
  #portal-breadcrumbs {
    padding-top: 11.5px;
  }
  
  .documentFirstHeading {
    /*background: none no-repeat scroll 0 0 #ccd6e0;*/
    margin: .7em -0.5em .3em -0.5em !important;
    padding: .1em .5em;
	
	nav {
		font-size: 70%;
		margin-top: 1em;
	}
  }

  .rich-item {

    img {
      width: 100%;
      height: auto;
      margin-bottom: 50px;
    }

    .rich-item-content {
      padding: 0;
      h3 {
        margin-top: 0;
      }
      a.rich-item-heading,
      {
        &,
        &:visited {
          color: transparent;
          h3 {
            color: $plone-text-color !important;
          }
        }
      }
      .rich-item-description {
        font-style: italic;
      }
      .more {
        display: block;
        padding-top: 0.5em;
      }
    }
  }
}


/* Accordion menu */

.acc-heading {
  font-weight: bold;
  color: #006699;
  cursor: pointer;
}

.acc-heading::before {
  content: '► ';
  color: #006699;
  padding-bottom: 0.5em;
}

.acc-opened::before {
  content: '▼ ';
  color: #006699;
}

.acc-bellows {
  /* */
}

.acc-heading > * {
  display: inline-block;
}

.acc-heading div div::before {
  content: 'Hier klicken!';
  color: white;
  font-size: 0px;
}


/* Control Panel / Toolbar cleanup */

#edit-zone {
  li {
    margin-bottom: 0;
  }
}

#content {
  padding: 0 1rem;
  margin-bottom: 1rem;

  .portlet.portletSiteSetup {
    ul {
      list-style-image: initial;
      list-style-type: none;
    }
    .row.configlets {
      margin: 0;
    }
  }

  .newsImage {
    display: block;
  }
}

article {
	clear: both;
}

article .documentByLine {
	display: none;
}

article.tileItem {
	border-top: 1px solid #B2C1D1;
	h2.tileHeadline {
		font-size: 140%;
		display: inline-block;
		width: 65%;
	}
	h2.tileHeadlineNoFloat {
		font-size: 140%;
		display: inline-block;
		width: auto;
	}
	.tileImage {
		float: right;
		padding: 1em 0 1em 1em;
		width: 30%;
	}
	.tileImage img {
		height: auto !important;
		width: 100% !important;
	}
	.tileFooter {
		display: block;
	}
	div.visualClear {
		clear: both;
		margin-bottom: 1em;
	}
}

#viewlet-below-content, .documentByLine, #portal-anontools {
    @media (max-width: 767.98px) {
        padding: 1rem;
        margin-left: -0.5rem;
    }
    @media (min-width: 768px) {
        padding: 0 1rem 0 0;
        margin-left: 0.5rem;
    }
}


#viewlet-below-content div.managePortletsLink {
    //display: none;
}

#portal-breadcrumbs {
	font-size: 80%;
}

#content-core div#portal-breadcrumbs {
	display: none;
}

#tinymce {
	padding: .5em;
	div.acc-heading::before {
		content: "►";
	}
	div.acc-heading {
		font-weight: bold;
		color: #006699;
	}
	div.acc-bellows {
	}
	.mce-textbox.mce-multiline {
		overflow-y: scroll;
	}
	.mce-content-body {
		padding: 0.7em;
	} 
}

.pat-autotoc .mce-tinymce {
	min-height: 250px;
}

.mosaic-tile-content {
	div.acc-heading{
		background-color: #ccd6e0;
		margin: .5em -0.6rem;
		padding: .3em .6rem;
	}
}

#content-core .row .col{
    word-break: normal;
}

#content-core .row .col .card {
    word-break: normal;
	background-image: url("../images/polaroid-multi.png");
	display: inline-block;
	overflow: hidden;
    height: 185px;
    width: 158px;
	margin: 0em;
    padding: 15px 7px 12px 12px;
	background-image: url("../images/polaroid-single.png");
	background-repeat: no-repeat;
	text-align: center;
    vertital-align: left;
}

.card-image img {
    max-width: 130px;
    max-height: 140px;
}

#content-core .row .col .card .card-body{
	background: none;
    padding: 0;
    font-size: 70%;
	width: 140px;
	height: 160px;
	vertical-align: top;
}


#calendar {
	.fc-daygrid-dot-event, .fc-daygrid-event-dot, fc-event-time {
		display: inline-block;
	}
	.fc-event-title {
		white-space: normal;
		overflow-wrap: anywhere;
		word-break: break-all;
	}
}

.vevent span.cal_month {
	background: #8099b2;
	font-size: 1em;
	padding: .3em 1em;
    display: block;
}

.vevent span.cal_day {
    font-size: 32px;
    font-weight: 700;
    display: block;
}

.vevent span.cal_wkday {
  font-size: 11px;
  font-weight: 300;
  display: block;
  padding: 0 12px 6px;
}

td.feed_date {
	padding-left: 0;
}

ul.event.summary {
	float: none;
	background: none;
	box-shadow: none;
	margin: 0 2rem 1rem 0 !important;
	list-style: none outside none !important;
	/*max-width: 15rem;*/
	li {
		margin: 0 0 .6em 0 !important;
		color: #666666;
		strong {
			color: #003366;
			font-weight: normal !important;
			font-size: 120%;
			padding-bottom: 0.3em;
		}
	}
}

ul.mode_selector {
	list-style: none outside none;
	li {
		display: inline;
	}
	li:nth-child(1) {
		margin-left: 0 !important;
	}
}

#content nav.pagination ul li {
	margin-left: 0;
}
#content nav.pagination li > a, #content nav.pagination li > span {
    padding: .3rem .6rem;
}

.vkpaging .pagination > ul > li {
	border: 1px solid #B2C1D1;
	color: #006699;
	float: left;
	list-style: none outside none;
	margin: 0 3px;
	padding: 2px 6px;
}

#content time.date_view {
	color: #666666;
	font-size: 11px;
	padding-bottom: 1rem;
}

.template-event_listing #content .documentFirstHeading nav {
	position: static;
}
#content div.event-box {
	display: table;
}
#content div.event-box > div {
	display: table-row;
}
#content div.event-box > div > div {
	display: table-cell;
}
#content div.event-box > div > div.cell-summary { width: 35%; }
#content div.event-box > div > div.cell-text { width: 65%; }

#content div.listingBar {
    font-size: 80%;
    margin: 1em 0;
	a {
		display: inline-block;
		padding: 0.25em 0.5em;
		border-width: 1px;
		border-style: solid;
	}
	a:hover {
		color: #FFFFFF;
		background-color: #003366;
		border-color: #003366;
	}
}

#portal-sitemap ul li::before {
	display: none;
}

.pat-autotoc:not(.autotabs) .autotoc-nav {
	float: none;
    display: block;
	background-color: #E8E8E0;
	margin: 0;
	padding: .3rem 0;
	box-shadow: none;
}

#user-group-sharing, table.listing {
	position: relative;
	z-index: 10;
}